import styled from 'styled-components';
import { colorPrincipal } from '../variables';

export const ServiceMenu = styled.div`
    margin-top: -11em;
    z-index: 1000;
    position: relative;

    & a{
        cursor: pointer;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-direction: column;
        & h3{
            text-transform: uppercase;
            color: ${colorPrincipal};
            font-weight: black;
            font-size: 1.7rem;
        }
    }

    @media (max-width: 500px){
        margin-top: 0;
    }
`;