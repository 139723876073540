import styled from 'styled-components';
import { colorOscuro } from '../variables';

export const ServiceItemContainer = styled.div`
    padding: 0 8em;
    margin-top: -4em;
    & h2{
        text-transform: uppercase;
        color: ${colorOscuro};
    }

    & p{
        width: 80%;
        color: black;
    }

    & > div > div:nth-of-type(2){
        padding-top: 2em;
        transform: translateY(2em);
    }
    @media (max-width: 500px){
        padding: 0;
        & p{
            width: 100%;
        }
        & > div > div:nth-of-type(2){
            padding-top: 0;
            transform: translateY(0);
        }
    }
`;

export const ServiceItemImage = styled.div`
  width: 100%;
`;