import React from "react"
import { css } from "styled-components"
import { Container, Grid } from "../styles"
import {
  ServiceItemContainer,
  ServiceItemImage,
} from "../styles/components/ServiceItem"
import Image from "./Image"
import ReactHtmlParser from "react-html-parser"
import { useLang } from "../hooks/useLang"
import marked from "marked"
import Img from "gatsby-image"

export default function ServiceItem({
  Name,
  Description,
  Images,
  Descripcion,
}) {
  const [lang, setLang] = useLang()

  const serviceId = Name.toLowerCase()
    ?.split(" ")
    .join("")
    ?.split("&")
    .join("-")

  const description = lang === "en" ? Description : Descripcion

  return (
    <section id={serviceId}>
      <Container
        css={css`
          @media (max-width: 500px) {
            padding-top: 0;
          }
        `}
      >
        <ServiceItemContainer>
          <h2>{Name}</h2>
          <p>{ReactHtmlParser(marked(description))}</p>
          <Grid
            columns={3}
            gap={3}
            responsive={`
                                gap: 1em;
                            `}
          >
            {Images.map(image => (
              <ServiceItemImage>
                <Img fluid={image.localFile.childImageSharp.fluid} />
              </ServiceItemImage>
            ))}
          </Grid>
        </ServiceItemContainer>
      </Container>
    </section>
  )
}
