import * as React from "react"

function Casing(props) {
  return (
    <svg
      width={'100%'}
      height={'100%'}
      viewBox="0 0 378 370"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M188.626 322.651L0 240.173l188.583-82.478 188.626 82.478-188.583 82.478z"
        fill="#BCBEFF"
      />
      <path
        d="M188.625 300.128L51.501 240.173l137.124-59.954 137.082 59.954-137.082 59.955z"
        fill="#33B1FF"
      />
      <path
        d="M188.625 184.284l132.438 57.922 4.644-2.033-137.082-59.954-137.124 59.954 4.643 2.033 132.481-57.922z"
        fill="#fff"
      />
      <path
        d="M377.207 240.173v46.757l-188.583 82.478v-46.757l188.583-82.478z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M0 240.173v46.757l188.626 82.478v-46.757L0 240.173z"
        fill="#24126A"
      />
      <path
        opacity={0.51}
        d="M12.78 261.18v14.714l163.067 71.346v-14.747L12.78 261.18z"
        fill="#283F69"
      />
      <path
        d="M188.626 319.973L3.067 238.818 0 240.173l188.626 82.478 188.583-82.478-3.067-1.355-185.516 81.155z"
        fill="#fff"
      />
      <path
        d="M348.924 272.926v8.39l-132.013 57.728v-8.358l132.013-57.76z"
        fill="#283F69"
      />
      <path
        opacity={0.51}
        d="M0 240.173v46.757l188.626 82.478v-46.757L0 240.173z"
        fill="#283F69"
      />
      <path
        d="M133.546 320.199l-34.164-14.941v3.066l34.164 14.94v-3.065zm-39.063-17.07l-9.286-4.066v3.098l9.243 4.033.043-3.065zm-43.366-18.974l-18.957-8.261v3.066l18.957 8.293v-3.098zm17.253 7.583l-13.675-6.002v3.098l13.675 5.97v-3.066zm12.863 5.582l-9.244-4.033-.043 3.098 9.287 4.033v-3.098zm107.564-96.514l-5.964-2.614 5.964-2.646 6.006 2.646-6.006 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M200.766 206.033l-5.964-2.613 5.964-2.614 6.006 2.614-6.006 2.613z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M212.736 211.293l-5.964-2.646 5.964-2.614 6.006 2.614-6.006 2.646z"
        fill="#283F69"
      />
      <path
        d="M224.749 216.521l-6.006-2.614 6.006-2.614 5.964 2.614-5.964 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M236.718 221.748l-6.006-2.614 6.006-2.613 5.964 2.613-5.964 2.614z"
        fill="#283F69"
      />
      <path
        d="M248.687 227.008l-6.006-2.646 6.006-2.614 6.007 2.614-6.007 2.646zm11.973 5.227l-5.964-2.614 5.964-2.613 6.006 2.613-6.006 2.614zm11.97 5.228l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M284.641 242.722l-6.007-2.646 6.007-2.614 5.963 2.614-5.963 2.646z"
        fill="#283F69"
      />
      <path
        d="M176.783 206.066l-6.006-2.614 6.006-2.646 5.964 2.646-5.964 2.614zm11.969 5.227l-6.006-2.614 6.006-2.613 6.007 2.613-6.007 2.614zm11.971 5.26l-5.964-2.646 5.964-2.614 6.006 2.614-6.006 2.646zm11.971 5.227l-5.964-2.613 5.964-2.614 6.006 2.614-6.006 2.613zm12.012 5.228l-6.007-2.614 6.007-2.614 5.963 2.614-5.963 2.614zm11.97 5.259l-6.006-2.646 6.006-2.613 5.964 2.613-5.964 2.646zm11.97 5.228l-6.006-2.614 6.006-2.614 6.007 2.614-6.007 2.614zm11.97 5.227l-5.963-2.613 5.963-2.614 6.007 2.614-6.007 2.613zm11.971 5.26l-5.964-2.646 5.964-2.614 6.006 2.614-6.006 2.646zm-107.859-36.657l-5.964-2.613 5.964-2.646 6.006 2.646-6.006 2.613zm12.013 5.228l-6.006-2.614 6.006-2.614 5.964 2.614-5.964 2.614zm11.968 5.227l-6.006-2.613 6.006-2.614 5.964 2.614-5.964 2.613z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M200.679 227.04l-6.006-2.614 6.006-2.646 6.007 2.646-6.007 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M212.65 232.268l-5.963-2.614 5.963-2.614 6.007 2.614-6.007 2.614zm11.972 5.259l-5.964-2.646 5.964-2.614 6.006 2.614-6.006 2.646z"
        fill="#283F69"
      />
      <path
        d="M236.632 242.754l-6.007-2.613 6.007-2.614 5.963 2.614-5.963 2.613zm11.971 5.228l-6.007-2.614 6.007-2.613 5.964 2.613-5.964 2.614zm11.97 5.26l-6.006-2.646 6.006-2.614 6.007 2.614-6.007 2.646z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M152.715 216.585l-6.007-2.614 6.007-2.646 6.006 2.646-6.006 2.614zm11.969 5.228l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M176.655 227.04l-5.964-2.614 5.964-2.613 6.006 2.613-6.006 2.614z"
        fill="#283F69"
      />
      <path
        d="M188.668 232.3l-6.006-2.614 6.006-2.646 5.964 2.646-5.964 2.614zm11.969 5.227l-6.006-2.613 6.006-2.614 5.964 2.614-5.964 2.613zm11.972 5.26l-6.007-2.646 6.007-2.614 6.006 2.614-6.006 2.646z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M224.579 248.015l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614z"
        fill="#283F69"
      />
      <path
        d="M236.548 253.242l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614zm12.014 5.26l-6.007-2.646 6.007-2.614 5.964 2.614-5.964 2.646zm-107.861-36.657l-6.006-2.614 6.006-2.646 5.964 2.646-5.964 2.614zm11.969 5.227l-6.006-2.613 6.006-2.614 6.007 2.614-6.007 2.613zm11.972 5.228l-5.963-2.614 5.963-2.614 6.007 2.614-6.007 2.614zm11.971 5.26l-5.964-2.614 5.964-2.646 6.006 2.646-6.006 2.614zm12.01 5.227l-6.006-2.614 6.006-2.613 5.964 2.613-5.964 2.614zm11.972 5.26l-6.007-2.646 6.007-2.614 5.964 2.614-5.964 2.646zm11.97 5.227l-6.006-2.613 6.006-2.614 5.964 2.614-5.964 2.613zm11.969 5.228l-6.006-2.614 6.006-2.614 6.007 2.614-6.007 2.614zm11.971 5.26l-5.964-2.647 5.964-2.613 6.006 2.613-6.006 2.647zm-107.859-36.657l-5.964-2.614 5.964-2.646 6.006 2.646-6.006 2.614zm12.014 5.227l-6.006-2.614 6.006-2.613 5.964 2.613-5.964 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M152.629 237.592l-6.006-2.646 6.006-2.614 5.964 2.614-5.964 2.646z"
        fill="#283F69"
      />
      <path
        d="M164.601 242.819l-6.007-2.613 6.007-2.614 6.006 2.614-6.006 2.613z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M176.569 248.047l-5.963-2.614 5.963-2.614 6.007 2.614-6.007 2.614z"
        fill="#283F69"
      />
      <path
        d="M188.54 253.307l-5.964-2.646 5.964-2.614 6.006 2.614-6.006 2.646zm12.013 5.227l-6.007-2.614 6.007-2.613 5.963 2.613-5.963 2.614zm11.97 5.228l-6.006-2.614 6.006-2.614 5.964 2.614-5.964 2.614zm11.97 5.259l-6.006-2.646 6.006-2.614 6.007 2.614-6.007 2.646zm-107.86-36.657l-6.007-2.613 6.007-2.614 6.006 2.614-6.006 2.613zm11.972 5.228l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M140.575 242.852l-5.964-2.646 5.964-2.614 6.007 2.614-6.007 2.646z"
        fill="#283F69"
      />
      <path
        d="M152.586 248.079l-6.007-2.614 6.007-2.614 5.963 2.614-5.963 2.614zm11.971 5.227l-6.006-2.613 6.006-2.614 5.964 2.614-5.964 2.613z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M176.526 258.566l-6.006-2.646 6.006-2.613 6.007 2.613-6.007 2.646z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M188.499 263.794l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614z"
        fill="#283F69"
      />
      <path
        d="M200.467 269.021l-5.964-2.614 5.964-2.613 6.006 2.613-6.006 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M212.48 274.281l-6.007-2.646 6.007-2.614 5.963 2.614-5.963 2.646z"
        fill="#283F69"
      />
      <path
        d="M104.621 237.624l-6.006-2.614 6.006-2.646 5.964 2.646-5.964 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M116.591 242.851l-6.006-2.613 6.006-2.614 6.007 2.614-6.007 2.613z"
        fill="#283F69"
      />
      <path
        d="M128.561 248.111l-5.963-2.646 5.963-2.614 6.007 2.614-6.007 2.646zm11.97 5.228l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614zm11.971 5.227l-5.964-2.614 5.964-2.613 6.006 2.613-6.006 2.614zm12.012 5.26l-6.007-2.646 6.007-2.614 5.964 2.614-5.964 2.646zm11.971 5.227l-6.006-2.614 6.006-2.613 5.964 2.613-5.964 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M188.456 274.281l-6.007-2.614 6.007-2.614 6.006 2.614-6.006 2.614z"
        fill="#283F69"
      />
      <path
        d="M200.424 279.541l-5.963-2.614 5.963-2.646 6.007 2.646-6.007 2.614zM92.567 242.884l-5.964-2.614 5.964-2.646 6.006 2.646-6.006 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M104.578 248.111l-6.007-2.613 6.007-2.614 5.964 2.614-5.964 2.613z"
        fill="#283F69"
      />
      <path
        d="M116.548 253.339l-6.006-2.614 6.006-2.614 5.964 2.614-5.964 2.614zm11.97 5.259l-6.006-2.613 6.006-2.646 6.007 2.646-6.007 2.613zm11.971 5.228l-5.963-2.614 5.963-2.614 6.007 2.614-6.007 2.614zm11.97 5.259l-5.964-2.646 5.964-2.613 6.006 2.613-6.006 2.646z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M164.47 274.313l-6.006-2.614 6.006-2.613 5.964 2.613-5.964 2.614z"
        fill="#283F69"
      />
      <path
        d="M176.442 279.54l-6.007-2.613 6.007-2.614 5.964 2.614-5.964 2.613zm11.969 5.26l-6.006-2.613 6.006-2.646 6.006 2.646-6.006 2.613z"
        fill="#283F69"
      />
      <path
        d="M188.626 243.82L0 161.342l188.583-82.51 188.626 82.51-188.583 82.478z"
        fill="#BCBEFF"
      />
      <path
        d="M188.625 221.296L51.501 161.342l137.124-59.987 137.082 59.987-137.082 59.954z"
        fill="#33B1FF"
      />
      <path
        d="M188.625 105.421l132.438 57.953 4.644-2.032-137.082-59.987-137.124 59.987 4.643 2.032 132.481-57.953z"
        fill="#fff"
      />
      <path
        d="M377.207 161.342v46.757l-188.583 82.477V243.82l188.583-82.478z"
        fill="#E9EAFE"
      />
      <path
        d="M0 161.342v46.757l188.626 82.477V243.82L0 161.342z"
        fill="#24126A"
      />
      <path
        opacity={0.51}
        d="M12.78 182.348v14.715l163.067 71.313v-14.715L12.78 182.348z"
        fill="#283F69"
      />
      <path
        d="M188.626 241.141L3.067 159.987 0 161.342l188.626 82.478 188.583-82.478-3.067-1.355-185.516 81.154z"
        fill="#fff"
      />
      <path
        d="M348.924 194.094v8.39l-132.013 57.728v-8.358l132.013-57.76z"
        fill="#283F69"
      />
      <path
        opacity={0.51}
        d="M0 161.342v46.757l188.626 82.477V243.82L0 161.342z"
        fill="#283F69"
      />
      <path
        d="M133.546 241.335l-34.164-14.94v3.097l34.164 14.941v-3.098zm-39.063-17.038l-9.286-4.065v3.097l9.243 4.034.043-3.066zm-43.366-18.973l-18.957-8.293v3.097l18.957 8.293v-3.097zm17.253 7.55l-13.675-5.969v3.065l13.675 6.002v-3.098zm12.863 5.615l-9.244-4.034-.043 3.098 9.287 4.034v-3.098zm107.564-96.515l-5.964-2.646 5.964-2.613 6.006 2.613-6.006 2.646z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M200.766 127.202l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M212.736 132.429l-5.964-2.614 5.964-2.613 6.006 2.613-6.006 2.614z"
        fill="#283F69"
      />
      <path
        d="M224.749 137.689l-6.006-2.646 6.006-2.614 5.964 2.614-5.964 2.646z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M236.718 142.916l-6.006-2.613 6.006-2.614 5.964 2.614-5.964 2.613z"
        fill="#283F69"
      />
      <path
        d="M248.687 148.144l-6.006-2.614 6.006-2.614 6.007 2.614-6.007 2.614zm11.973 5.26l-5.964-2.614 5.964-2.646 6.006 2.646-6.006 2.614zm11.97 5.227l-5.964-2.614 5.964-2.613 6.006 2.613-6.006 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M284.641 163.859l-6.007-2.614 6.007-2.614 5.963 2.614-5.963 2.614z"
        fill="#283F69"
      />
      <path
        d="M176.783 127.202l-6.006-2.614 6.006-2.614 5.964 2.614-5.964 2.614zm11.969 5.259l-6.006-2.613 6.006-2.646 6.007 2.646-6.007 2.613zm11.971 5.228l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614zm11.971 5.26l-5.964-2.646 5.964-2.614 6.006 2.614-6.006 2.646zm12.012 5.227l-6.007-2.614 6.007-2.613 5.963 2.613-5.963 2.614zm11.97 5.228l-6.006-2.614 6.006-2.614 5.964 2.614-5.964 2.614zm11.97 5.259l-6.006-2.613 6.006-2.646 6.007 2.646-6.007 2.613zm11.97 5.228l-5.963-2.614 5.963-2.614 6.007 2.614-6.007 2.614zm11.971 5.227l-5.964-2.613 5.964-2.614 6.006 2.614-6.006 2.613zm-107.859-36.624l-5.964-2.646 5.964-2.614 6.006 2.614-6.006 2.646zm12.013 5.227l-6.006-2.613 6.006-2.614 5.964 2.614-5.964 2.613zm11.968 5.228l-6.006-2.614 6.006-2.614 5.964 2.614-5.964 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M200.679 148.208l-6.006-2.646 6.006-2.613 6.007 2.613-6.007 2.646z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M212.65 153.436l-5.963-2.614 5.963-2.614 6.007 2.614-6.007 2.614zm11.972 5.227l-5.964-2.613 5.964-2.614 6.006 2.614-6.006 2.613z"
        fill="#283F69"
      />
      <path
        d="M236.632 163.923l-6.007-2.646 6.007-2.614 5.963 2.614-5.963 2.646zm11.971 5.228l-6.007-2.614 6.007-2.614 5.964 2.614-5.964 2.614zm11.97 5.227l-6.006-2.614 6.006-2.613 6.007 2.613-6.007 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M152.715 137.754l-6.007-2.646 6.007-2.614 6.006 2.614-6.006 2.646zm11.969 5.227l-5.964-2.614 5.964-2.613 6.006 2.613-6.006 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M176.655 148.209l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614z"
        fill="#283F69"
      />
      <path
        d="M188.668 153.468l-6.006-2.614 6.006-2.646 5.964 2.646-5.964 2.614zm11.969 5.228l-6.006-2.614 6.006-2.614 5.964 2.614-5.964 2.614zm11.972 5.227l-6.007-2.614 6.007-2.613 6.006 2.613-6.006 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M224.579 169.183l-5.964-2.646 5.964-2.614 6.006 2.614-6.006 2.646z"
        fill="#283F69"
      />
      <path
        d="M236.548 174.41l-5.964-2.613 5.964-2.614 6.006 2.614-6.006 2.613zm12.014 5.228l-6.007-2.614 6.007-2.614 5.964 2.614-5.964 2.614zm-107.861-36.625l-6.006-2.646 6.006-2.613 5.964 2.613-5.964 2.646zm11.969 5.228l-6.006-2.614 6.006-2.614 6.007 2.614-6.007 2.614zm11.972 5.227l-5.963-2.614 5.963-2.613 6.007 2.613-6.007 2.614zm11.971 5.26l-5.964-2.646 5.964-2.614 6.006 2.614-6.006 2.646zm12.01 5.228l-6.006-2.614 6.006-2.614 5.964 2.614-5.964 2.614zm11.972 5.227l-6.007-2.614 6.007-2.614 5.964 2.614-5.964 2.614zm11.97 5.26l-6.006-2.614 6.006-2.646 5.964 2.646-5.964 2.614zm11.969 5.227l-6.006-2.614 6.006-2.613 6.007 2.613-6.007 2.614zm11.971 5.228l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614zm-107.859-36.625l-5.964-2.646 5.964-2.614 6.006 2.614-6.006 2.646zm12.014 5.228l-6.006-2.614 6.006-2.614 5.964 2.614-5.964 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M152.629 158.728l-6.006-2.614 6.006-2.613 5.964 2.613-5.964 2.614z"
        fill="#283F69"
      />
      <path
        d="M164.601 163.988l-6.007-2.646 6.007-2.614 6.006 2.614-6.006 2.646z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M176.569 169.215l-5.963-2.613 5.963-2.614 6.007 2.614-6.007 2.613z"
        fill="#283F69"
      />
      <path
        d="M188.54 174.443l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614zm12.013 5.259l-6.007-2.646 6.007-2.613 5.963 2.613-5.963 2.646zm11.97 5.228l-6.006-2.614 6.006-2.614 5.964 2.614-5.964 2.614zm11.97 5.227l-6.006-2.613 6.006-2.614 6.007 2.614-6.007 2.613zm-107.86-36.624l-6.007-2.646 6.007-2.614 6.006 2.614-6.006 2.646zm11.972 5.227l-5.964-2.613 5.964-2.614 6.006 2.614-6.006 2.613z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M140.575 163.988l-5.964-2.614 5.964-2.614 6.007 2.614-6.007 2.614z"
        fill="#283F69"
      />
      <path
        d="M152.586 169.248l-6.007-2.614 6.007-2.646 5.963 2.646-5.963 2.614zm11.971 5.227l-6.006-2.614 6.006-2.613 5.964 2.613-5.964 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M176.526 179.703l-6.006-2.614 6.006-2.614 6.007 2.614-6.007 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M188.499 184.962l-5.964-2.646 5.964-2.614 6.006 2.646-6.006 2.614z"
        fill="#283F69"
      />
      <path
        d="M200.467 190.19l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M212.48 195.417l-6.007-2.614 6.007-2.613 5.963 2.613-5.963 2.614z"
        fill="#283F69"
      />
      <path
        d="M104.621 158.793l-6.006-2.646 6.006-2.614 5.964 2.614-5.964 2.646z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M116.591 164.02l-6.006-2.614 6.006-2.614 6.007 2.614-6.007 2.614z"
        fill="#283F69"
      />
      <path
        d="M128.561 169.248l-5.963-2.614 5.963-2.614 6.007 2.614-6.007 2.614zm11.97 5.259l-5.964-2.646 5.964-2.613 6.006 2.613-6.006 2.646zm11.971 5.228l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614zm12.012 5.227l-6.007-2.614 6.007-2.613 5.964 2.613-5.964 2.614zm11.971 5.26l-6.006-2.614 6.006-2.646 5.964 2.646-5.964 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M188.456 195.449l-6.007-2.613 6.007-2.614 6.006 2.614-6.006 2.613z"
        fill="#283F69"
      />
      <path
        d="M200.424 200.677l-5.963-2.614 5.963-2.614 6.007 2.614-6.007 2.614zM92.567 164.052l-5.964-2.646 5.964-2.614 6.006 2.614-6.006 2.646z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M104.578 169.28l-6.007-2.614 6.007-2.614 5.964 2.614-5.964 2.614z"
        fill="#283F69"
      />
      <path
        d="M116.548 174.507l-6.006-2.614 6.006-2.613 5.964 2.613-5.964 2.614zm11.97 5.26l-6.006-2.646 6.006-2.614 6.007 2.614-6.007 2.646zm11.971 5.227l-5.963-2.613 5.963-2.614 6.007 2.614-6.007 2.613zm11.97 5.228l-5.964-2.614 5.964-2.613 6.006 2.613-6.006 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M164.47 195.482l-6.006-2.614 6.006-2.646 5.964 2.646-5.964 2.614z"
        fill="#283F69"
      />
      <path
        d="M176.442 200.709l-6.007-2.614 6.007-2.613 5.964 2.613-5.964 2.614zm11.969 5.26l-6.006-2.646 6.006-2.614 6.006 2.614-6.006 2.646z"
        fill="#283F69"
      />
      <path
        d="M188.626 164.988L0 82.51 188.583 0l188.626 82.51-188.583 82.478z"
        fill="#BCBEFF"
      />
      <path
        d="M188.625 142.465L51.501 82.51l137.124-59.987L325.707 82.51l-137.082 59.955z"
        fill="#283F69"
      />
      <path
        d="M188.625 26.59l132.438 57.953 4.644-2.033-137.082-59.987L51.501 82.51l4.643 2.033L188.625 26.59z"
        fill="#fff"
      />
      <path
        d="M377.207 82.51v46.757l-188.583 82.478v-46.757L377.207 82.51z"
        fill="#E9EAFE"
      />
      <path
        d="M0 82.51v46.757l188.626 82.478v-46.757L0 82.51z"
        fill="#24126A"
      />
      <path
        opacity={0.51}
        d="M12.78 103.517v14.714l163.067 71.313V174.83L12.78 103.517z"
        fill="#283F69"
      />
      <path
        d="M188.626 162.31L3.067 81.155 0 82.51l188.626 82.478L377.209 82.51l-3.067-1.355-185.516 81.155z"
        fill="#fff"
      />
      <path
        d="M348.924 115.262v8.358l-132.013 57.76v-8.39l132.013-57.728z"
        fill="#283F69"
      />
      <path
        opacity={0.51}
        d="M0 82.51v46.757l188.626 82.478v-46.757L0 82.51z"
        fill="#283F69"
      />
      <path
        d="M133.546 162.503l-34.164-14.94v3.098l34.164 14.94v-3.098zm-39.063-17.07l-9.286-4.033v3.098l9.243 4.033.043-3.098zm-43.366-18.941l-18.957-8.293v3.098l18.957 8.261v-3.066zm17.253 7.551l-13.675-5.97v3.066l13.675 6.001v-3.097zm12.863 5.614l-9.244-4.033-.043 3.065 9.287 4.066v-3.098zM188.797 43.11l-5.964-2.613 5.964-2.614 6.006 2.614-6.006 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M200.766 48.37l-5.964-2.646 5.964-2.613 6.006 2.613-6.006 2.646z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M212.736 53.598l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614z"
        fill="#283F69"
      />
      <path
        d="M224.749 58.825l-6.006-2.614 6.006-2.613 5.964 2.613-5.964 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M236.718 64.085l-6.006-2.614 6.006-2.646 5.964 2.646-5.964 2.614z"
        fill="#283F69"
      />
      <path
        d="M248.687 69.313l-6.006-2.614 6.006-2.614 6.007 2.614-6.007 2.614zm11.973 5.227l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614zm11.97 5.26l-5.964-2.614 5.964-2.646 6.006 2.646-6.006 2.613z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M284.641 85.027l-6.007-2.614 6.007-2.614 5.963 2.614-5.963 2.614z"
        fill="#283F69"
      />
      <path
        d="M176.783 48.37l-6.006-2.613 6.006-2.614 5.964 2.614-5.964 2.613zm11.969 5.26l-6.006-2.614 6.006-2.646 6.007 2.646-6.007 2.614zm11.971 5.228l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614zm11.971 5.227l-5.964-2.614 5.964-2.613 6.006 2.613-6.006 2.614zm12.012 5.26l-6.007-2.614 6.007-2.646 5.963 2.646-5.963 2.614zm11.97 5.227l-6.006-2.614 6.006-2.613 5.964 2.614-5.964 2.613zm11.97 5.228l-6.006-2.614 6.006-2.614 6.007 2.614-6.007 2.613zm11.97 5.26l-5.963-2.615 5.963-2.645 6.007 2.645-6.007 2.614zm11.971 5.227l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614zM164.728 53.63l-5.964-2.614 5.964-2.613 6.006 2.613-6.006 2.614zm12.013 5.26l-6.006-2.614 6.006-2.646 5.964 2.646-5.964 2.614zm11.968 5.227l-6.006-2.614 6.006-2.613 5.964 2.614-5.964 2.613z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M200.679 69.345l-6.006-2.614 6.006-2.614 6.007 2.614-6.007 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M212.65 74.605l-5.963-2.614 5.963-2.646 6.007 2.646-6.007 2.614zm11.972 5.227l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614z"
        fill="#283F69"
      />
      <path
        d="M236.632 85.06l-6.007-2.614 6.007-2.614 5.963 2.614-5.963 2.614zm11.971 5.26l-6.007-2.615 6.007-2.646 5.964 2.646-5.964 2.614zm11.97 5.227l-6.006-2.614 6.006-2.614 6.007 2.614-6.007 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M152.715 58.89l-6.007-2.614 6.007-2.614 6.006 2.614-6.006 2.614zm11.969 5.26l-5.964-2.647 5.964-2.613 6.006 2.614-6.006 2.646z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M176.655 69.377l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614z"
        fill="#283F69"
      />
      <path
        d="M188.668 74.604l-6.006-2.613 6.006-2.614 5.964 2.614-5.964 2.613zm11.969 5.26l-6.006-2.614 6.006-2.645 5.964 2.645-5.964 2.614zm11.972 5.228l-6.007-2.614 6.007-2.614 6.006 2.614-6.006 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M224.579 90.352l-5.964-2.647 5.964-2.613 6.006 2.614-6.006 2.645z"
        fill="#283F69"
      />
      <path
        d="M236.548 95.579l-5.964-2.614 5.964-2.614 6.006 2.614-6.006 2.614zm12.014 5.227l-6.007-2.613 6.007-2.614 5.964 2.614-5.964 2.613zM140.701 64.15l-6.006-2.614 6.006-2.614 5.964 2.614-5.964 2.613zm11.969 5.26l-6.006-2.614 6.006-2.647 6.007 2.647-6.007 2.613zm11.972 5.227l-5.963-2.614 5.963-2.614 6.007 2.614-6.007 2.614zm11.971 5.227l-5.964-2.614 5.964-2.613 6.006 2.613-6.006 2.614zm12.01 5.26l-6.006-2.614 6.006-2.646 5.964 2.646-5.964 2.614zm11.972 5.227l-6.007-2.613 6.007-2.614 5.964 2.614-5.964 2.613zm11.97 5.228l-6.006-2.614 6.006-2.614 5.964 2.614-5.964 2.614zm11.969 5.26l-6.006-2.614 6.006-2.646 6.007 2.646-6.007 2.614zm11.971 5.227l-5.964-2.614 5.964-2.613 6.006 2.613-6.006 2.614zM128.646 69.41l-5.964-2.615 5.964-2.613 6.006 2.613-6.006 2.614zm12.014 5.259l-6.006-2.614 6.006-2.646 5.964 2.646-5.964 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M152.629 79.896l-6.006-2.613 6.006-2.614 5.964 2.614-5.964 2.613z"
        fill="#283F69"
      />
      <path
        d="M164.601 85.124l-6.007-2.614 6.007-2.614 6.006 2.614-6.006 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M176.569 90.384l-5.963-2.614 5.963-2.646 6.007 2.646-6.007 2.614z"
        fill="#283F69"
      />
      <path
        d="M188.54 95.611l-5.964-2.614 5.964-2.613 6.006 2.613-6.006 2.614zm12.013 5.228l-6.007-2.614 6.007-2.614 5.963 2.614-5.963 2.614zm11.97 5.259l-6.006-2.613 6.006-2.646 5.964 2.646-5.964 2.613zm11.97 5.228l-6.006-2.614 6.006-2.614 6.007 2.614-6.007 2.614zm-107.86-36.657l-6.007-2.614 6.007-2.614 6.006 2.614-6.006 2.614zm11.972 5.26l-5.964-2.646 5.964-2.614 6.006 2.646-6.006 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M140.575 85.156l-5.964-2.614 5.964-2.614 6.007 2.614-6.007 2.614z"
        fill="#283F69"
      />
      <path
        d="M152.586 90.383l-6.007-2.613 6.007-2.614 5.963 2.614-5.963 2.614zm11.971 5.26l-6.006-2.613 6.006-2.646 5.964 2.646-5.964 2.613z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M176.526 100.871l-6.006-2.614 6.006-2.614 6.007 2.614-6.007 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M188.499 106.131l-5.964-2.646 5.964-2.614 6.006 2.614-6.006 2.646z"
        fill="#283F69"
      />
      <path
        d="M200.467 111.358l-5.964-2.614 5.964-2.613 6.006 2.613-6.006 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M212.48 116.586l-6.007-2.614 6.007-2.614 5.963 2.614-5.963 2.614z"
        fill="#283F69"
      />
      <path
        d="M104.621 79.928l-6.006-2.613 6.006-2.614 5.964 2.614-5.964 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M116.591 85.188l-6.006-2.614 6.006-2.645 6.007 2.645-6.007 2.614z"
        fill="#283F69"
      />
      <path
        d="M128.561 90.416l-5.963-2.614 5.963-2.614 6.007 2.614-6.007 2.614zm11.97 5.227l-5.964-2.614 5.964-2.613 6.006 2.614-6.006 2.613zm11.971 5.26l-5.964-2.614 5.964-2.646 6.006 2.646-6.006 2.614zm12.012 5.228l-6.007-2.614 6.007-2.614 5.964 2.614-5.964 2.614zm11.971 5.227l-6.006-2.614 6.006-2.613 5.964 2.613-5.964 2.614z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M188.456 116.618l-6.007-2.614 6.007-2.646 6.006 2.646-6.006 2.614z"
        fill="#283F69"
      />
      <path
        d="M200.424 121.845l-5.963-2.614 5.963-2.613 6.007 2.613-6.007 2.614zM92.567 85.188l-5.964-2.613 5.964-2.614 6.006 2.614-6.006 2.613z"
        fill="#283F69"
      />
      <path
        opacity={0.1}
        d="M104.578 90.448l-6.007-2.614 6.007-2.646 5.964 2.646-5.964 2.614z"
        fill="#283F69"
      />
      <path
        d="M116.548 95.676l-6.006-2.614 6.006-2.614 5.964 2.614-5.964 2.614zm11.97 5.259l-6.006-2.646 6.006-2.613 6.007 2.613-6.007 2.646zm11.971 5.228l-5.963-2.614 5.963-2.614 6.007 2.614-6.007 2.614zm11.97 5.227l-5.964-2.613 5.964-2.614 6.006 2.614-6.006 2.613z"
        fill="#283F69"
      />
      <path
        opacity={0.4}
        d="M164.47 116.618l-6.006-2.614 6.006-2.614 5.964 2.614-5.964 2.614z"
        fill="#283F69"
      />
      <path
        d="M176.442 121.877l-6.007-2.613 6.007-2.646 5.964 2.646-5.964 2.613zm11.969 5.228l-6.006-2.614 6.006-2.614 6.006 2.614-6.006 2.614z"
        fill="#283F69"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={282.894}
          y1={340.56}
          x2={282.894}
          y2={242.916}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0BFF6" />
          <stop offset={0.29} stopColor="#C5C4F7" />
          <stop offset={0.64} stopColor="#D3D2F9" />
          <stop offset={1} stopColor="#E9E9FD" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={22120.9}
          y1={19700.3}
          x2={22120.9}
          y2={15789.7}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0BFF6" />
          <stop offset={0.29} stopColor="#C5C4F7" />
          <stop offset={0.64} stopColor="#D3D2F9" />
          <stop offset={1} stopColor="#E9E9FD" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={22120.9}
          y1={16463}
          x2={22120.9}
          y2={12553.7}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#33B1FF" />
          <stop offset={0.29} stopColor="#C5C4F7" />
          <stop offset={0.64} stopColor="#33B1FF" />
          <stop offset={1} stopColor="#E9E9FD" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Casing
