import { graphql, useStaticQuery } from "gatsby"

export const useTech = () => {
  const tech = useStaticQuery(
    graphql`
      query {
        allStrapiTech {
          edges {
            node {
              Name
              Description
              Descripcion
              Images {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1200) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
        }
      }
    `
  )

  return tech.allStrapiTech.edges
}
