import * as React from "react"

function Computer(props) {
  return (
    <svg
      width={'100%'}
      height={'100%'}
      viewBox="0 0 284 224"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        opacity={0.51}
        d="M125.055 0l158.183 69.53-19.609 88.033-158.068-69.477L125.055 0z"
        fill="#283F69"
      />
      <path
        d="M95.952 94.876L114.85 9.311l-1.526-.485 2.288-1.002a2.065 2.065 0 011.759.097l151.141 66.574c.645.31 1.168.747 1.515 1.264a2.6 2.6 0 01.455 1.678l-19.067 86.227a.893.893 0 01-.235.519 1.32 1.32 0 01-.549.354l-2.224.986-.403-1.616h.127L97.901 97.834c-.644-.313-1.165-.754-1.509-1.274a2.597 2.597 0 01-.44-1.684z"
        fill="#7580AF"
      />
      <path
        d="M267.579 76.839l2.5-.84c.274.448.384.945.318 1.438l-19.067 86.227a.89.89 0 01-.235.519 1.326 1.326 0 01-.549.354l-2.225.986-.402-1.616h.127l-7.585-3.346 27.118-83.722z"
        fill="#7580AF"
      />
      <path
        d="M95.528 98.868l151.078 66.542c1.165.501 2.118.21 2.373-.711l19.067-86.228a2.604 2.604 0 00-.461-1.668c-.346-.515-.868-.949-1.509-1.257L114.998 9.004c-1.165-.517-2.119-.21-2.373.711L93.558 95.943a2.607 2.607 0 00.46 1.668c.347.515.869.949 1.51 1.257z"
        fill="#24126A"
      />
      <path
        opacity={0.7}
        d="M154.51 223.095a8.731 8.731 0 01-3.348-.647L1.568 156.585a2.672 2.672 0 01-1.064-.65 1.798 1.798 0 01-.504-.966c.027-.279.149-.548.353-.78.204-.232.484-.42.812-.545l89.384-39.373c.94-.333 1.97-.49 3.005-.459a8.116 8.116 0 012.948.637l149.532 65.863c.422.143.788.367 1.063.65.275.284.449.616.504.966a1.37 1.37 0 01-.348.789 2.038 2.038 0 01-.817.553l-89.32 39.291a6.634 6.634 0 01-2.606.534zM93.07 114.482a5.5 5.5 0 00-2.118.371L1.674 154.145c-.2.07-.373.178-.503.314a.872.872 0 00-.239.461c.046.245.176.475.377.668.201.193.466.342.767.432l149.51 65.862c.775.329 1.64.516 2.526.547a7.348 7.348 0 002.58-.369l89.236-39.307c.202-.068.377-.176.508-.312a.83.83 0 00.233-.464c0-.372-.424-.776-1.144-1.099L95.994 115.031a7.277 7.277 0 00-2.924-.549z"
        fill="#6B5E9A"
      />
      <path
        d="M.572 139.695l8.262 3.233 82.626-36.415a7.667 7.667 0 012.729-.4 7.489 7.489 0 012.674.578l142.582 62.792c1.355-.582 7.584-2.861 7.584-2.861L244 172.618c-.165.306-.465.558-.848.712l-87.075 38.337a7.587 7.587 0 01-2.715.408 7.447 7.447 0 01-2.666-.569L4.809 147.292c-.55-.215-.985-.569-1.229-1.003l-3.008-6.594z"
        fill="#BCBEFF"
      />
      <path
        d="M1.865 140.729l149.51 65.863a7.669 7.669 0 002.739.581 7.842 7.842 0 002.79-.419l89.236-39.291c1.419-.631 1.229-1.714-.403-2.441L96.207 99.159a7.755 7.755 0 00-2.738-.583 7.944 7.944 0 00-2.792.405L1.568 138.29c-1.525.63-1.356 1.713.297 2.44z"
        fill="#EAEAFE"
      />
      <path
        d="M196.479 192.337v-1.245c0-.129 0-.177-.211-.129l-5.784 2.505a.452.452 0 00-.154.135.309.309 0 00-.058.172v1.31c0 .129.106.177.212.129l5.784-2.554a.422.422 0 00.16-.14.3.3 0 00.051-.183zm-8.643 3.814v-1.26c0-.114 0-.162-.212-.114l-5.784 2.538a.46.46 0 00-.156.143.332.332 0 00-.056.18v1.245c0 .113 0 .178.212.113l5.784-2.538a.47.47 0 00.15-.136.356.356 0 00.062-.171z"
        fill="#24126A"
      />
      <path
        d="M222.878 163.778L98.622 109.051a3.271 3.271 0 00-1.335-.278c-.47 0-.93.096-1.335.278l-44.258 19.524a.878.878 0 00-.35.267.599.599 0 00-.127.364c0 .127.044.253.128.364.084.11.204.202.35.266l124.192 54.711c.411.175.874.267 1.345.267s.935-.092 1.346-.267l44.3-19.509a.87.87 0 00.349-.266.6.6 0 00.128-.364.598.598 0 00-.128-.364.86.86 0 00-.349-.266z"
        fill="#ADB1F2"
      />
      <path
        d="M222.878 165.038l-.445.194-123.811-54.533a3.42 3.42 0 00-1.335-.265 3.42 3.42 0 00-1.335.265L52.14 130.095l-.445-.194a.878.878 0 01-.35-.267.599.599 0 01-.127-.364c0-.127.044-.253.128-.364a.886.886 0 01.35-.266l44.257-19.524a3.271 3.271 0 011.335-.278c.47 0 .93.096 1.334.278l124.257 54.726c.126.067.23.157.301.261a.59.59 0 01.109.337.59.59 0 01-.109.338.836.836 0 01-.301.26z"
        fill="#9198DB"
      />
      <path
        d="M92.605 113.997l107.943 47.534 4.788-2.101-107.943-47.551-4.788 2.118zm-11.759 5.188l101.227 44.593 4.809-2.118-101.248-44.592-4.788 2.117zm-7.669 3.362l119.192 52.512 4.81-2.117-119.214-52.497-4.788 2.102zm-7.67 3.378L184.7 178.437l4.809-2.117-119.193-52.497-4.809 2.102zm-7.669 3.378l119.214 52.496 4.788-2.101-119.193-52.513-4.809 2.118zm149.214 35.105l4.809 2.117 4.788-2.117-4.788-2.117-4.809 2.117zm-16.928 2.909l9.978 4.38 4.788-2.101-9.957-4.396-4.809 2.117z"
        fill="#24126A"
      />
      <path
        d="M93.833 177.015l-33.495-14.757c-1.314-.565-1.314-1.503 0-2.085l22.902-10.085a6.552 6.552 0 012.373-.436c.825 0 1.638.149 2.373.436l33.495 14.756c.287.073.536.213.713.4a.938.938 0 01.271.643c0 .23-.095.455-.271.642a1.48 1.48 0 01-.713.4l-22.902 10.086a6.574 6.574 0 01-2.373.436c-.825 0-1.638-.15-2.373-.436z"
        fill="#EAEAFE"
      />
      <path
        d="M121.481 165.701l-33.495-14.757a6.573 6.573 0 00-2.373-.436c-.825 0-1.638.15-2.373.436L60.338 161.03c-.345.142-.63.355-.826.614-.34-.501 0-1.067.826-1.471l22.902-10.085a6.552 6.552 0 012.373-.436c.825 0 1.638.149 2.373.436l33.495 14.756c.911.404 1.186.97.826 1.471a1.957 1.957 0 00-.826-.614z"
        fill="#9198DB"
      />
      <path
        opacity={0.11}
        d="M106.55 45.213l135.278 59.579-5.374 2.363-135.252-59.58 5.348-2.362zm141.817 62.467l8.366 3.675-5.348 2.363-8.365-3.675 5.347-2.363z"
        fill="#24126A"
      />
      <path
        d="M178.704 65.282l.53-2.376 2.712 1.196-.53 2.376-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.1}
        d="M177.624 70.067l.551-2.393 2.691 1.197-.53 2.392-2.712-1.197z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M176.544 74.835l.55-2.376 2.712 1.196-.551 2.376-2.711-1.197z"
        fill="#33B1FF"
      />
      <path
        d="M175.463 79.619l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M174.404 84.387l.529-2.392 2.712 1.212-.529 2.376-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M173.323 89.17l.53-2.391 2.712 1.196-.53 2.392-2.712-1.196zm-1.08 4.769l.551-2.392 2.69 1.212-.529 2.376-2.712-1.196zm-1.081 4.784l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.1}
        d="M170.103 103.491l.529-2.392 2.712 1.196-.53 2.392-2.711-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M173.259 62.89l.551-2.392 2.691 1.196-.53 2.392-2.712-1.196zm-1.08 4.768l.551-2.376 2.711 1.18-.55 2.392-2.712-1.196zm-1.059 4.784l.529-2.392 2.712 1.196-.551 2.392-2.69-1.196zm-1.081 4.768l.53-2.392 2.712 1.196-.53 2.392-2.712-1.196zm-1.08 4.785l.529-2.393L172.2 80.8l-.529 2.391-2.712-1.195zm-1.081 4.768l.53-2.392 2.711 1.196-.529 2.392-2.712-1.196zm-1.08 4.784l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196zm-1.081 4.768l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196zm-1.059 4.768l.53-2.376 2.712 1.196-.53 2.376-2.712-1.196zm3.157-40.601l.551-2.392 2.691 1.196-.53 2.392-2.712-1.196zm-1.081 4.784l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196zm-1.059 4.768l.529-2.392 2.712 1.196-.551 2.392-2.69-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.1}
        d="M164.595 74.802l.529-2.376 2.712 1.196-.53 2.392-2.711-1.212z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M163.514 79.586l.53-2.392 2.711 1.196-.529 2.392-2.712-1.196zm-1.08 4.784l.551-2.392 2.711 1.196-.55 2.376-2.712-1.18z"
        fill="#33B1FF"
      />
      <path
        d="M161.353 89.138l.551-2.392 2.712 1.196-.551 2.393-2.712-1.197zm-1.059 4.768l.53-2.376 2.711 1.197-.529 2.375-2.712-1.196zm-1.081 4.784l.53-2.391 2.712 1.195-.53 2.393-2.712-1.197z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M162.37 58.074l.551-2.376 2.712 1.196-.551 2.376-2.712-1.196zm-1.06 4.784l.53-2.392 2.712 1.196-.551 2.392-2.691-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.1}
        d="M160.23 67.626l.53-2.376 2.712 1.196-.53 2.376-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M159.15 72.41l.529-2.392 2.712 1.196-.529 2.392-2.712-1.196zm-1.08 4.768l.55-2.376 2.712 1.196-.551 2.376-2.711-1.196zm-1.081 4.784l.551-2.392 2.711 1.196-.55 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M155.929 86.73l.53-2.376 2.712 1.196-.53 2.376-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M154.849 91.514l.53-2.392 2.711 1.196-.529 2.392-2.712-1.196zm-1.081 4.768l.53-2.376 2.712 1.196-.53 2.376-2.712-1.196zm3.157-40.6l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196zm-1.059 4.768l.53-2.376 2.712 1.196-.53 2.376-2.712-1.196zm-1.081 4.784l.53-2.392 2.712 1.196-.53 2.392-2.712-1.196zm-1.08 4.768l.529-2.376 2.712 1.196-.53 2.376-2.711-1.196zm-1.081 4.784l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196zm-1.059 4.768l.529-2.376 2.712 1.18-.529 2.392-2.712-1.196zm-1.08 4.784l.529-2.392 2.712 1.196-.529 2.392-2.712-1.196zm-1.081 4.768l.53-2.392 2.712 1.196-.53 2.392-2.712-1.196zm-1.08 4.784l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196zm3.177-40.617l.53-2.392 2.712 1.213-.53 2.375-2.712-1.196zm-1.08 4.785l.529-2.392 2.712 1.196-.53 2.392-2.711-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M149.34 62.826l.53-2.392 2.712 1.212-.53 2.376-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M148.26 67.61l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.1}
        d="M147.18 72.378l.55-2.392 2.712 1.196-.551 2.392-2.711-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M146.12 77.162l.53-2.392 2.712 1.196-.53 2.392-2.712-1.196zm-1.08 4.768l.53-2.392 2.711 1.196-.529 2.392-2.712-1.196zm-1.081 4.784l.551-2.392 2.691 1.196-.53 2.392-2.712-1.196zm-1.08 4.768l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196zm3.178-40.601l.529-2.392 2.712 1.196-.529 2.392-2.712-1.196zm-1.081 4.769l.53-2.393 2.712 1.196-.53 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M143.896 60.434l.551-2.392 2.712 1.195-.551 2.393-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M142.815 65.202l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196zm-1.059 4.768l.529-2.376 2.712 1.196-.53 2.376-2.711-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.1}
        d="M140.675 74.754l.53-2.392 2.712 1.196-.53 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M139.595 79.522l.551-2.376 2.69 1.196-.529 2.392-2.712-1.212z"
        fill="#33B1FF"
      />
      <path
        d="M138.515 84.306l.551-2.392 2.711 1.196-.55 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.1}
        d="M137.455 89.074l.53-2.376 2.712 1.196-.551 2.376-2.691-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M140.612 48.473l.53-2.392 2.711 1.196-.529 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M139.531 53.257l.551-2.392 2.712 1.196-.551 2.376-2.712-1.18z"
        fill="#33B1FF"
      />
      <path
        d="M138.451 58.025l.55-2.392 2.712 1.196-.551 2.392-2.711-1.196zm-1.06 4.768l.53-2.376 2.712 1.196-.53 2.376-2.712-1.196zm-1.08 4.784l.53-2.392 2.712 1.196-.53 2.392-2.712-1.196zm-1.08 4.768l.529-2.376 2.712 1.197-.53 2.375-2.711-1.196zm-1.081 4.785l.551-2.392 2.69 1.195-.529 2.393-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M133.091 81.897l.53-2.375 2.711 1.196-.55 2.376-2.691-1.197z"
        fill="#33B1FF"
      />
      <path
        d="M132.01 86.682l.53-2.392 2.711 1.196-.529 2.392-2.712-1.196zm3.157-40.617l.53-2.376 2.712 1.196-.53 2.376-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.1}
        d="M134.086 50.85l.551-2.393 2.712 1.196-.551 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M133.027 55.617l.53-2.376 2.712 1.196-.53 2.376-2.712-1.196zm-1.08 4.784l.53-2.392 2.712 1.196-.53 2.392-2.712-1.196zm-1.081 4.769l.53-2.377 2.712 1.196-.53 2.376-2.712-1.196zm-1.08 4.783l.55-2.392 2.712 1.196-.551 2.392-2.711-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M128.705 74.721l.551-2.376 2.712 1.196-.551 2.376-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M127.646 79.505l.529-2.391 2.712 1.195-.551 2.393-2.69-1.197zm-1.08 4.768l.529-2.375 2.712 1.18-.529 2.391-2.712-1.195zm101.057 2.522l.53-2.392 2.711 1.196-.55 2.392-2.691-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.1}
        d="M226.542 91.563l.53-2.376 2.712 1.196-.53 2.376-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M225.462 96.347l.53-2.392 2.712 1.196-.53 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M224.381 101.115l.551-2.376 2.712 1.196-.551 2.376-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M223.322 105.899l.53-2.392 2.712 1.196-.551 2.392-2.691-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M222.242 110.667l.529-2.392 2.712 1.196-.529 2.392-2.712-1.196zm-1.081 4.784l.53-2.392 2.711 1.196-.529 2.392-2.712-1.196zm-1.08 4.768l.529-2.376 2.712 1.18-.529 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.1}
        d="M219 125.003l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M222.178 84.387l.53-2.392 2.712 1.212-.53 2.376-2.712-1.196zm-1.08 4.783l.53-2.391 2.711 1.196-.529 2.392-2.712-1.196zm-1.081 4.769l.551-2.392 2.691 1.212-.53 2.376-2.712-1.196zm-1.08 4.784l.55-2.392 2.712 1.196-.551 2.392-2.711-1.196zm-1.06 4.768l.53-2.392 2.712 1.196-.53 2.392-2.712-1.196zm-1.08 4.784l.529-2.392 2.712 1.196-.529 2.392-2.712-1.196zm-1.08 4.768l.529-2.392 2.712 1.196-.53 2.392-2.711-1.196zm-1.081 4.784l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196zm-1.081 4.768l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196zm3.178-40.6l.53-2.393 2.712 1.197-.53 2.391-2.712-1.195zm-1.081 4.768l.551-2.392 2.691 1.196-.53 2.392-2.712-1.196zm-1.08 4.784l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.1}
        d="M213.513 96.315l.53-2.392 2.712 1.196-.551 2.392-2.691-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M212.432 101.083l.53-2.376 2.712 1.196-.53 2.376-2.712-1.196zm-1.08 4.784l.53-2.392 2.712 1.196-.53 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M210.272 110.635l.551-2.376 2.711 1.196-.55 2.392-2.712-1.212zm-1.081 4.784l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196zm-1.059 4.768l.53-2.376 2.711 1.196-.529 2.392-2.712-1.212z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M211.289 79.586l.551-2.392 2.69 1.196-.529 2.392-2.712-1.196zm-1.081 4.784l.551-2.392 2.712 1.196-.551 2.376-2.712-1.18z"
        fill="#33B1FF"
      />
      <path
        opacity={0.1}
        d="M209.128 89.138l.551-2.392 2.712 1.196-.551 2.393-2.712-1.197z"
        fill="#33B1FF"
      />
      <path
        d="M208.068 93.906l.53-2.376 2.712 1.197-.53 2.375-2.712-1.196zm-1.08 4.784l.529-2.391 2.712 1.195-.53 2.393-2.711-1.197zm-1.081 4.768l.53-2.375 2.712 1.196-.53 2.376-2.712-1.197z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M204.827 108.243l.551-2.392 2.711 1.196-.55 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M203.768 113.011l.529-2.376 2.712 1.196-.53 2.376-2.711-1.196zm-1.081 4.784l.53-2.392 2.711 1.196-.529 2.392-2.712-1.196zm3.157-40.617l.551-2.376 2.69 1.196-.529 2.376-2.712-1.196zm-1.06 4.784l.53-2.392 2.712 1.196-.551 2.392-2.691-1.196zm-1.08 4.768l.53-2.376 2.711 1.196-.529 2.376-2.712-1.196zm-1.081 4.784l.53-2.392 2.712 1.196-.53 2.392-2.712-1.196zm-1.08 4.768l.53-2.376 2.712 1.196-.53 2.376-2.712-1.196zm-1.08 4.785l.55-2.393 2.712 1.197-.551 2.392-2.711-1.196zm-1.06 4.767l.53-2.375 2.711 1.179-.55 2.392-2.691-1.196zm-1.08 4.785l.529-2.393 2.712 1.197-.53 2.392-2.711-1.196zm-1.081 4.767l.53-2.375 2.711 1.196-.529 2.376-2.712-1.197zm3.157-40.6l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196zm-1.059 4.768l.529-2.376 2.712 1.18-.529 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M198.259 84.338l.53-2.392 2.712 1.196-.53 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M197.179 89.106l.53-2.392 2.711 1.196-.529 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.1}
        d="M196.098 93.89l.551-2.392 2.711 1.196-.55 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M195.018 98.658l.55-2.392 2.712 1.196-.551 2.392-2.711-1.196zm-1.06 4.784l.53-2.392 2.712 1.196-.53 2.392-2.712-1.196zm-1.08 4.768l.529-2.392 2.712 1.196-.53 2.392-2.711-1.196zm-1.08 4.785l.55-2.393 2.691 1.196-.53 2.393-2.711-1.196zm3.178-40.617l.529-2.392 2.712 1.196-.551 2.392-2.69-1.196zm-1.082 4.784l.53-2.392 2.712 1.196-.53 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M192.814 81.93l.53-2.392 2.712 1.196-.53 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M191.733 86.714l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196zm-1.08 4.768l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.1}
        d="M189.594 96.266l.53-2.392 2.712 1.196-.53 2.376-2.712-1.18z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M188.514 101.034l.529-2.392 2.712 1.196-.53 2.392-2.711-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M187.433 105.802l.53-2.376 2.711 1.196-.529 2.376-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.1}
        d="M186.353 110.586l.551-2.392 2.69 1.196-.53 2.392-2.711-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M189.531 69.97l.529-2.376 2.712 1.196-.529 2.376-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M188.45 74.754l.53-2.392 2.711 1.196-.529 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M187.37 79.522l.53-2.376 2.711 1.196-.529 2.392-2.712-1.212zm-1.081 4.784l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196zm-1.06 4.768l.53-2.376 2.712 1.196-.551 2.376-2.691-1.196zm-1.08 4.784l.53-2.392 2.712 1.196-.53 2.392-2.712-1.196zm-1.08 4.768l.529-2.376 2.712 1.196-.529 2.376-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M181.989 103.41l.55-2.392 2.691 1.196-.53 2.392-2.711-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M180.908 108.178l.551-2.376 2.712 1.196-.551 2.376-2.712-1.196zm3.178-40.601l.529-2.392 2.712 1.196-.53 2.392-2.711-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.1}
        d="M183.005 72.345l.53-2.376 2.712 1.197-.53 2.375-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M181.924 77.13l.551-2.392 2.712 1.195-.551 2.393-2.712-1.196zm-1.059 4.767l.53-2.375 2.712 1.196-.551 2.376-2.691-1.197zm-1.08 4.785l.53-2.392 2.712 1.196-.53 2.392-2.712-1.196zm-1.081 4.768l.53-2.376 2.712 1.196-.53 2.376-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        opacity={0.4}
        d="M177.624 96.234l.551-2.392 2.691 1.196-.53 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <path
        d="M176.544 101.002l.55-2.392 2.712 1.212-.551 2.376-2.711-1.196zm-1.081 4.784l.551-2.392 2.712 1.196-.551 2.392-2.712-1.196z"
        fill="#33B1FF"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={270.419}
          y1={86.617}
          x2={95.909}
          y2={86.617}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0BFF6" />
          <stop offset={0.29} stopColor="#C5C4F7" />
          <stop offset={0.64} stopColor="#D3D2F9" />
          <stop offset={1} stopColor="#E9E9FD" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={270.418}
          y1={120.769}
          x2={240.461}
          y2={120.769}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5761D7" />
          <stop offset={0.18} stopColor="#5C66D9" />
          <stop offset={0.39} stopColor="#6A73DF" />
          <stop offset={0.61} stopColor="#8289E8" />
          <stop offset={0.85} stopColor="#A3A7F5" />
          <stop offset={1} stopColor="#BCBEFF" />
        </linearGradient>
        <linearGradient
          id="prefix__paint2_linear"
          x1={-545375}
          y1={17353.8}
          x2={-516704}
          y2={17353.8}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#5761D7" />
          <stop offset={0.18} stopColor="#5C66D9" />
          <stop offset={0.39} stopColor="#6A73DF" />
          <stop offset={0.61} stopColor="#8289E8" />
          <stop offset={0.85} stopColor="#A3A7F5" />
          <stop offset={1} stopColor="#BCBEFF" />
        </linearGradient>
        <linearGradient
          id="prefix__paint3_linear"
          x1={-535997}
          y1={17353.7}
          x2={-507298}
          y2={17353.7}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0BFF6" />
          <stop offset={0.29} stopColor="#C5C4F7" />
          <stop offset={0.64} stopColor="#D3D2F9" />
          <stop offset={1} stopColor="#E9E9FD" />
        </linearGradient>
        <linearGradient
          id="prefix__paint4_linear"
          x1={-135889}
          y1={4750.1}
          x2={-134008}
          y2={4750.1}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C0BFF6" />
          <stop offset={0.29} stopColor="#C5C4F7" />
          <stop offset={0.64} stopColor="#D3D2F9" />
          <stop offset={1} stopColor="#E9E9FD" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Computer
