import * as React from "react"
import Seo from "../components/seo"
import Hero from "../components/Hero"
import { Button, Container, Grid } from "../styles"
import Chip from "../assets/Chip"
import Computer from "../assets/Computer"
import Phone from "../assets/Phone"
import Casing from "../assets/Casing"
import { ServiceMenu } from "../styles/pages/services"
import ServiceItem from "../components/ServiceItem"
import { css } from "styled-components"
import { services } from "../api/db.json"
import { useTranslate } from "react-translate"
import { usePages } from "../hooks/usePages"
import { useTech } from "../hooks/useTech"
import { useLang } from "../hooks/useLang"
import { useItems } from "../hooks/useItems"

const Services = ({ pageContext }) => {
  const [lang, setLang] = useLang()

  const techItems = useTech()

  const buttonText = useItems(
    lang === "en" ? "Button English" : "Button Spanish"
  ).content[0].text

  const { name } = pageContext

  const page = usePages(name)

  const info = page.Cuerpo[0]

  return (
    <>
      <Seo title={info.Title} />
      <Hero bg={"bg-tech.png"} title={info.Title}></Hero>
      <ServiceMenu>
        <Container>
          <Grid
            columns={4}
            gap={4}
            responsive={`
                display: none;
            `}
            css={css`
              & a {
                text-decoration: none;
              }
            `}
          >
            <a href="#hardware">
              <Chip />
              <h3>Hardware</h3>
            </a>
            <a href="#software">
              <Computer />
              <h3>Software</h3>
            </a>
            <a href="#app-web">
              <Phone />
              <h3>App & Web</h3>
            </a>
            <a href="#casing">
              <Casing />
              <h3>Casing</h3>
            </a>
          </Grid>
        </Container>
      </ServiceMenu>

      {techItems.map(({ node }) => (
        <ServiceItem id={node.id} key={node.id} {...node} />
      ))}

      <Button to="/contact" big={true}>
        {buttonText}
      </Button>
      <br />
    </>
  )
}

export default Services
